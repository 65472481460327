<template>
  <div class="inpatient_container">
    <div class="mask" v-if="show"></div>
    <div class="popup" v-if="show">
      <h1>请输入充值金额</h1>
      <div class="ipt">{{ value }}</div>
      <div class="keyboard">
        <div class="num">
          <el-button type="primary" @click.native="inputNum(0)">0</el-button>
          <el-button type="primary" @click.native="inputNum(1)">1</el-button>
          <el-button type="primary" @click.native="inputNum(2)">2</el-button>
          <el-button type="primary" @click.native="inputNum(3)">3</el-button>
          <el-button type="primary" @click.native="inputNum(4)">4</el-button>
          <el-button type="primary" @click.native="inputNum(5)">5</el-button>
          <el-button type="primary" @click.native="inputNum(6)">6</el-button>
          <el-button type="primary" @click.native="inputNum(7)">7</el-button>
          <el-button type="primary" @click.native="inputNum(8)">8</el-button>
          <el-button type="primary" @click.native="inputNum(9)">9</el-button>
        </div>
        <div class="funtion">
          <el-button type="primary" @click.native="del">删除</el-button>
          <el-button type="primary" @click.native="clear">清空</el-button>
        </div>
      </div>
      <div class="btn">
        <el-button type="danger" @click.native="cancel">取消</el-button>
        <el-button type="primary" @click.native="confirm">确认</el-button>
      </div>
    </div>
    <el-row class="inpatient_header">
      <el-col :span="24">{{ name }}</el-col>
    </el-row>
    <Count></Count>
    <div class="file_row">
      <div class="input">
        <span>住院号:</span>
        <input v-model="hospitalNum" class="cdrNum" />
      </div>
      <div class="input">
        <span>名字:</span>
        <input v-model="patName" class="cdrNum" />
      </div>
      <el-button type="primary" class="search" @click.native="getHospita">查询</el-button>
    </div>
    <div class="inpatient_main">
      <el-table
        :data="tableData"
        v-loading="loading"
        border=""
        style="width: 100%"
        height="60%"
        ref="multipleTable"
        highlight-current-row
        :row-class-name="tableRowClassName"
        @row-click="toggleSelection(index)"
      >
        <el-table-column type="index"> </el-table-column>
        <!-- <el-table-column type="selection"
                         width="60"></el-table-column> -->
        <el-table-column label="住院人" prop="patName">
          <template slot-scope="scope">
            <span>{{ scope.row.patName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="住院科室" prop="deptName">
          <template slot-scope="scope">
            <span>{{ scope.row.deptName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="入院日期" prop="inDate">
          <template slot-scope="scope">
            <span>{{ scope.row.inDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="住院押金余额" prop="balance">
          <template slot-scope="scope">
            <span>{{ scope.row.balance }}</span>
          </template>
        </el-table-column>
        <el-table-column label="住院总费用" prop="balance">
          <template slot-scope="scope">
            <span>{{ scope.row.totalFee }}</span>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status">
          <template slot-scope="scope">
            <span>{{ scope.row.status == '1' ? '在院' : '已出院' }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div>
        <img :src="'data:image/png;base64,'+imgurl"
             alt="">
      </div> -->
      <el-button type="danger" @click.native="recharge" class="recharge">住院押金充值</el-button>
    </div>
    <el-button type="primary" @click.native="home" class="home">首页</el-button>
    <!-- <el-button type="primary"
               @click.native="back"
               class="back">返回</el-button> -->
    <Foo></Foo>
  </div>
</template>
<script>
import Foo from '../footer/index'
import Count from '../count/index'
import { jsonPost } from '@/baseAPI'
export default {
  components: {
    Foo,
    Count
  },
  data() {
    return {
      value: '',
      show: false,
      name: '',
      tableData: [],
      loading: false,
      hospitalNum: '',
      patName: ''
    }
  },
  created() {
    this.name = this.$route.query.name
    this.patCardNo = this.$route.query.patCardNo
  },
  methods: {
    // 住院按金补缴支付
    getHospita() {
      let _this = this
      const params = {
        admissionNum: this.hospitalNum,
        patName: this.patName
        // admissionNum: '177537',
        // patName: '住院病人'
      }
      if (!this.patName) {
        this.$message({
          message: '姓名不能为空',
          type: 'error'
        })
      } else {
        this.loading = true
        Promise.all([_this.$api.getHospita(params)]).then(res => {
          console.log(res)
          if (res[0].code === 0) {
            this.tableData = res[0].data.item
            this.loading = false
          } else {
            this.loading = false
            this.$message({
              message: res[0].msg,
              type: 'error'
            })
          }
        })
      }
    },

    getNum() {},
    toggleSelection(rows) {
      return
      this.$refs.multipleTable.toggleRowSelection(row.row_index)
    },
    tableRowClassName({ row, rowIndex }) {
      row.row_index = rowIndex
    },
    home() {
      this.$router.push('/home')
    },
    back() {
      this.$router.back()
    },
    recharge() {
      this.show = true
      // if (this.$refs.multipleTable.selection.length === 0) {
      //   alert('请选择住院记录项')
      // } else {
      //   this.show = true
      // }
    },
    inputNum(num) {
      this.value += num
    },
    confirm() {
      const that = this
      if (that.value === '') {
        this.$message({
          message: '金额不能为空',
          type: 'success'
        })
      } else if (that.value === 0 || that.value === '0') {
        this.$message({
          message: '金额不能为零',
          type: 'success'
        })
      } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(that.value)) {
        this.$message({
          message: '输入金额有误，请重新输入',
          type: 'success'
        })
      } else {
        that.$router.push({
          path: '/mode',
          query: {
            name: that.name,
            patName: that.tableData[0].patName,
            regFee: that.value,
            admissionNum: this.hospitalNum
          }
        })
      }
    },
    del() {
      this.value = this.value.substr(0, this.value.length - 1)
    },
    clear() {
      this.value = ''
    },
    cancel() {
      this.show = false
      this.value = ''
    }
  }
}
</script>
<style lang="less">
.input {
  width: 45%;
  height: 100%;
}

/deep/.el-input__inner {
  border-color: #3b77d8 !important;
  margin-left: 15px;
  border: #3172ff 2px solid !important;
  border-radius: 8px;
  height: 100%;
  font-size: 46px !important;
  color: #000000 !important;
}

.bgc {
  background-color: red;
}

.inpatient_container {
  position: relative;
  width: 100%;
  height: 100%;
}

.inpatient_container .mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}

.inpatient_container .popup {
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #dcdfe6;
  width: 70%;
  height: 40%;
  border-radius: 10px;
  padding: 20px 30px;
  z-index: 999;
}

.inpatient_container .popup h1 {
  text-align: center;
  color: red;
  letter-spacing: 10px;
  margin: 10px 0;
  font-size: 30px;
}

.inpatient_container .popup p {
  margin-top: 20px;
  color: red;
  font-size: 18px;
}

.inpatient_container .popup .btn {
  margin-top: 30px;
}

.inpatient_container .popup .btn .el-button {
  font-size: 20px !important;
}

.inpatient_container .popup .ipt {
  border: 2px solid #000;
  padding-left: 20px;
  font-size: 40px;
  width: 500px;
  height: 60px;
  margin: 0 auto;
  margin-top: 30px;
  line-height: 60px;
}

.inpatient_container .popup .keyboard {
  margin: 0 auto;
  width: 500px;
  margin-top: 20px;
}

.inpatient_container .popup .keyboard .num {
  width: 350px;
  float: left;
}

.inpatient_container .popup .keyboard .num .el-button {
  margin: 0;
  float: left;
  width: 60px;
  height: 60px;
  font-size: 40px !important;
  margin: 10px;
}

.inpatient_container .popup .keyboard .funtion {
  width: 100px;
  float: left;
}

.inpatient_container .popup .keyboard .funtion .el-button {
  margin: 0;
  width: 130px;
  height: 60px;
  font-size: 40px !important;
  margin: 10px;
}

.inpatient_container .popup .btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 30px;
  left: 0;
}

.inpatient_container .popup .btn .el-button {
  font-size: 30px !important;
  margin: 0 40px;
}

.inpatient_header {
  background-color: #81ace4;
  height: 100px;
  font-size: 38px;
  font-weight: 700;
  line-height: 100px;
  color: white;
  letter-spacing: 20px;
  padding-left: 30px;
}

.inpatient_footer {
  position: fixed !important;
  bottom: 0;
  left: 0;
  z-index: 1;
  height: 30px;
  width: 100%;
  background-color: #81ace4;
  font-size: 14px;
  color: white;
  text-align: center;
  line-height: 30px;
}

.back {
  position: absolute;
  bottom: 80px;
  right: 150px;
  font-size: 26px !important;
}

.home {
  width: 220px;
  height: 120px;
  position: absolute;
  bottom: 80px;
  right: 30px;
  font-size: 26px !important;
}

.search {
  width: 220px;
  height: 120px;
  right: 30px;
  font-size: 26px !important;
}

.inpatient_main {
  margin: 0 auto;
  margin-top: 10%;
  width: 90%;
  height: 70%;
}

.inpatient_main th {
  font-size: 26px !important;
  background-color: #0359c8 !important;
  text-align: center !important;
  color: white !important;
}

.inpatient_main td {
  font-size: 26px !important;
  color: #0359c8 !important;
  text-align: center !important;
  background-clip: padding-box !important;
}

.inpatient_container .el-checkbox__inner {
  width: 30px !important;
  height: 30px !important;
  margin-top: -7px !important;
  margin-left: 20px !important;
}

.el-checkbox__inner::after {
  left: 7px !important;
  top: 4px !important;
}

.el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
  top: 8px !important;
}

.recharge {
  height: 80px;
  position: absolute;
  bottom: 320px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px !important;
}

.file_row {
  height: 80px;
  display: flex;
  justify-content: space-around;
  margin-top: 30%;
  padding: 0 45px;
}

.file_row .cdrNum {
  width: 50%;
  font-size: 60px;
}

.file_row span {
  font-size: 60px;
  line-height: 80px;
}
</style>
